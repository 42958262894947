import { useState} from 'react';
import Button from '@mui/material/Button'; 
import EditableText from '../../Common/EditableText';
import './Verse.css'; 

const Verse =(props) => {

    const [verseText, setVerseText] = useState(props.text);
    const handleChange = (event) => {
    setVerseText(event.target.value);
  };
    return (<>
     <div>
      <EditableText
                key={props.verseNumber}
                text={props.text}  onChange={handleChange} />
                
                </div>
    </>)
}


export default Verse; 