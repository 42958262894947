import React, { useState, useContext } from 'react';
import Button from '@mui/material/Button';
import BibleService from '../../Service/Bible'; 
//import { UserContext } from '../../Service/UserContext';



const EditableText =(props) => 
{  
  //const { user } = useContext(UserContext);
  const [text, setText] = useState({ isEditable: false, text: props.text });
  const [key, setKey] =  useState(props.key);
  const cancelTextChange = () => {
    setText({ ...text, isEditable: false, text: props.text }); 
  } 

  const updateVerse = () => {
    console.log("verse called")
    setText({ ...text, isEditable: false }); 
    let isUpdated = BibleService.updateVerse({text: text.text, id: props.id, email: 'kimanidb@gmail.com'})
    if(isUpdated)
    {
      
    }

  }; 
  const res1 = (
    <div>
      <textarea
        value={text.text}
        onChange={(e) => setText({ ...text, text: e.target.value})}
        className="w-[750px] h-[200px] border
    border-4 border-red-400 m-10 bg-gray-100"
      ></textarea>
      
                <Button onClick={()=>cancelTextChange()} >
                    Cancel
                </Button>
                <Button  onClick={()=>updateVerse()} >
                    Update
                </Button>
    </div>
  );
  const res2 = (
    <div
      style={{ whiteSpace: "pre-line" }}
      onClick={() => setText({ ...text, isEditable: true })}
      className="w-[750px] h-[200px] border border-4 border-green-700
    bg-green-300 m-10"
    >
      {text.text}
    </div>
  );

  return <div>{1 === 1 && text.isEditable  ? res1 : res2}</div>;
}

export default EditableText;
