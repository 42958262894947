import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import './Download.css';

const Download = () => {
    return (<div class="home-content">
        <ul>
            <li> <a href="/Assets/Document/Sagrada Biblia.pdf" download><PictureAsPdfIcon  />Scanned PDF </a>   </li>
            
        </ul>
        
    </ div>)

}

export default Download; 
